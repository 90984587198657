<template>
  <div class="bg_white">

    <div id="deduction" v-if="order">
      <div class="flow_t" style="font-size: 16px; margin-bottom: 6.4vw; padding-bottom: 0">{{ business.name }}</div>

      <div style="text-align: center; font-size: 24px; font-weight: bold;">{{ formatAmount(obj.payableAmount) }}</div>

      <div class="flow_b" style="margin-top: 6.4vw">
        <ul>
          <li class="clear">
            <span>当前状态</span>
            <label>{{ getStateDesc(obj.state) }}</label>
          </li>
          <li class="clear">
            <span>消费者</span>
            <label>{{ businessCustomerUser.name }}</label>
          </li>
          <li class="clear">
            <span>消费时间</span>
            <label>{{ obj.consumeTime | date('yyyy-MM-dd HH:mm') }}</label>
          </li>
          <li class="clear">
            <span>扣费时间</span>
            <label>{{ obj.updatedTime | date('yyyy-MM-dd HH:mm') }}</label>
          </li>
          <li class="clear">
            <span>扣费方式</span>
            <label>{{ getConsumeType(obj.type) }}</label>
          </li>
          <li class="clear">
            <span>交易单号</span>
            <label>{{ obj.code }}</label>
          </li>
          <li class="clear" v-if="keyword.length>0">
            <span>备注</span>
            <label>{{ getRemark() }}</label>
          </li>
          <li class="clear" v-if="order.faceImgUrl">
            <span>抓拍照片</span>
            <label>
              <img :src="'http://'+order.faceImgUrl+'?x-oss-process=image/resize,m_lfit,w_146/quality,q_70'"/>
            </label>
          </li>
        </ul>
      </div>
    </div>

    <div id="balanceRefund" v-if="!order">
      <div class="flow_t" style="font-size: 16px; margin-bottom: 6.4vw; padding-bottom: 0">{{ obj.businessName }}</div>

      <div style="text-align: center; font-size: 24px; font-weight: bold;">{{ formatAmount(obj.refundAmount) }}</div>

      <div class="flow_b" style="margin-top: 6.4vw">
        <ul>
          <li class="clear">
            <span>当前状态</span>
            <label>系统退回成功</label>
          </li>
          <li class="clear">
            <span>消费者</span>
            <label>{{ obj.businessCustomerUserName }}</label>
          </li>
          <li class="clear">
            <span>退回时间</span>
            <label>{{ obj.refundTime }}</label>
          </li>
          <li class="clear">
            <span>退回方式</span>
            <label>原路退回</label>
          </li>
          <li class="clear" v-if="keyword.length>0">
            <span>备注</span>
            <label>{{ getRemark() }}</label>
          </li>
        </ul>
      </div>
    </div>

    <div class="loading_all" v-if="loading">
      <van-loading type="spinner" color="#ffffff" vertical>加载中...</van-loading>
    </div>
  </div>
</template>


<style scoped>
</style>

<script>
import {post} from '@/utils/http'
import {formatAmount} from "../utils/util"

export default {
  data() {
    return {
      id: null,
      record: {},
      obj: {},
      keyword: [],
      business: {},
      businessCustomerUser: {},
      loading: false,
      order: null,
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.loading = true
    post('api/push/v1/' + this.id + '/read', {}).then(res => {
      this.record = res.data;
      if (this.record.payload && this.record.payload.params && this.record.payload.params.keyword) {
        const snapshot = JSON.parse(this.record.payload.params.snapshoot);
        if (snapshot instanceof Object) {
          this.obj = snapshot;
        } else {
          this.obj = JSON.parse(JSON.parse(this.record.payload.params.snapshoot));
        }

        this.keyword = this.record.payload.params.keyword ? this.record.payload.params.keyword : [];

        if (this.obj && this.obj.type && this.obj.type === 'BALANCE_REFUND' ) {

        } else {
          this.businessCustomerUser = this.obj.businessCustomerUser ? this.obj.businessCustomerUser : {};
          this.business = this.obj.businessCustomerUser && this.obj.businessCustomerUser.business ? this.obj.businessCustomerUser.business : {};

          this.fetchOrder(this.obj.id);
        }

      }
      this.loading = false
    }).catch(() => this.loading = false)


  },
  methods: {
    fetchOrder(id) {
      post('api/business/v1/purchase/' + id + '/read', {}).then(res => {
        this.order = res.data;

      })
    },
    getRemark() {
      let find = this.keyword.find(item => item.name === 'remark');
      if (!find) return '-';
      return find.value;
    },
    getStateDesc(state) {
      // if (state === 'UNLIQUIDATED') return '未结算';
      // if (state === 'NEW') return '新订单';
      // if (state === 'CONFIRM') return '扣费中';
      if (state) {
        if (state === 'FAIL') return '扣费失败'
        else {
          if (state === 'PARTRECEIVED')
            return '扣费成功（部分扣费）';
          else
            return '扣费成功';
        }
      } else {
        return '';
      }
      // if (state === 'CANCEL') return '取消';
      // if (state === 'REFUND') return '退款';
    },
    getConsumeType(type) {
      if (type) {
        if (type === 'PRODUCT_BUNDLE') return '餐费包消费';
        else {
          return '实时扣费';
        }
      } else {
        return '';
      }
    },
    formatAmount(amount) {
      if (amount) {
        if (this.obj.type && this.obj.type === 'BALANCE_REFUND') {
          return formatAmount(amount);
        } else {
          return "-" + formatAmount(amount);
        }

      } else {
        return "0.00";
      }
    },
  }
};
</script>
